<template>
  <b-card>
    <b-form
      class="mt-1"
      @submit.prevent="submit"
    >
      <b-row>
        <b-col cols="12">
          <h3 class="mb-3">
            {{ pageTitle }}
          </h3>
          <div>
            <label for="state-select">Selecione o estado:</label>
            <v-select
              id="state-select"
              v-model="stateId"
              :reduce="state => state.id"
              :options="statesList"
              label="name"
              @input="onStateChange"
            >
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!stateId"
                  v-bind="attributes"
                  v-on="events"
                >
              </template>
            </v-select>
          </div>

          <div class="mt-1">
            <label for="city-select">Selecione a cidade:</label>
            <v-select
              id="city-select"
              v-model="neighborhoodPayload.city_id"
              :reduce="city => city.id"
              :options="citiesList"
              label="name"
              @input="onCityChange"
            >
              <template #search="{attributes, events}">
                <input
                  class="vs__search"
                  :required="!neighborhoodPayload.city_id"
                  v-bind="attributes"
                  v-on="events"
                >
              </template>
            </v-select>
          </div>

          <div class="mt-2 mb-2 d-flex justify-content-between align-items-center">
            <h3>
              Bairros
            </h3>

            <b-button
              variant="secondary"
              @click="addNeighborhood"
            >
              Adicionar bairro
            </b-button>
          </div>

          <div
            v-for="(item, index) in neighborhoodPayload.neighborhoods"
            :key="index"
            class=""
          >
            <div class="mt-1 d-flex">
              <b-form-input
                v-model="item.name"
                placeholder="Insira o nome do bairro"
                required
              />
              <b-button
                variant="danger"
                size="sm"
                class="ml-1"

                @click="removeNeighborhood(item)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  size="20"
                />
              </b-button>

            </div>

          </div>

          <b-button
            class="mt-2 py-1 btn-block"
            variant="primary"
            type="submit"
          >
            {{ buttonLabel }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import axios from '@axios'
import {
  onBeforeMount, ref, reactive, computed,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import { useRouter } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const toast = useToast()
    const { router } = useRouter()

    const stateId = ref(0)
    const statesList = ref([])
    const citiesList = ref([])

    const neighborhoodPayload = reactive({
      city_id: null,
      neighborhoods: [],
    })

    const pageTitle = computed(() => (props.id !== 0 ? 'Editar bairros' : 'Novo bairro'))
    const buttonLabel = computed(() => (props.id !== 0 ? 'Salvar' : 'Criar'))

    const fetchStatesList = async () => {
      try {
        const { data } = await axios.get('/states')
        statesList.value = data.data
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar a listagem de estados',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const fetchCitiesList = async () => {
      try {
        const { data } = await axios.get(`/cities/${stateId.value}`)
        citiesList.value = data.data
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar a listagem de cidades',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const fetchNeighborhoodsByCity = async (id = props.id) => {
      try {
        const { data } = await axios.get(`/admin/neighborhoods/cities/${id}`)
        if (data.length > 0) {
          neighborhoodPayload.city_id = data[0].city_id
          stateId.value = data[0].state_id

          neighborhoodPayload.neighborhoods = data.map(item => ({
            id: item.id,
            name: item.name,
          }))
        }
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao buscar listagem de bairros',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const sendNeighborhoodsInfo = async () => {
      const action = props.id !== 0 ? 'editado(s)' : 'adicionado(s)'
      try {
        await axios.post('/admin/neighborhoods', neighborhoodPayload)
        toast({
          component: ToastificationContent,
          props: {
            title: `Bairro(s) ${action} com sucesso!`,
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })

        router.push({ name: 'apps-neighborhoods-list' })
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao enviar as informações',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const clearCitiesSelection = () => {
      neighborhoodPayload.city_id = null
      citiesList.value = []
    }

    const onStateChange = id => {
      clearCitiesSelection()
      if (id) fetchCitiesList()
    }

    const onCityChange = id => {
      fetchNeighborhoodsByCity(id)
    }

    const addNeighborhood = () => {
      neighborhoodPayload.neighborhoods.push({
        id: null,
        name: '',
      })
    }

    const removeNeighborhood = async item => {
      if (item.id) {
        try {
          const payload = {
            name: item.name,
            city_id: neighborhoodPayload.city_id,
          }
          await axios.delete(`/admin/neighborhoods/${item.id}`, payload)
        } catch (error) {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error ao tentar remover bairro',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      }

      neighborhoodPayload.neighborhoods = neighborhoodPayload.neighborhoods.filter(value => value.id !== item.id)
    }

    const submit = async () => {
      sendNeighborhoodsInfo()
    }

    onBeforeMount(async () => {
      fetchStatesList()

      if (props.id !== 0) {
        await fetchNeighborhoodsByCity()
        await fetchCitiesList()
      }
    })

    return {
      stateId,
      statesList,
      citiesList,
      submit,
      neighborhoodPayload,
      addNeighborhood,
      removeNeighborhood,
      onStateChange,
      onCityChange,
      pageTitle,
      buttonLabel,
    }
  },
}
</script>
